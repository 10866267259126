import React from "react"
import { connect } from "react-redux"
/*import Link from 'gatsby-link'*/
import { graphql, navigate } from "gatsby"
import GoTrue from "gotrue-js"
import get from "lodash/get"
import Img from "gatsby-image"
import Helmet from "react-helmet"
import Layout from "../components/layout"
import PageHero from "../components/regNewHero"
import Container from "../components/container"
import ScrollAnimation from "react-animate-on-scroll"
import RegisterForm from "../components/RegisterForm/RegisterForm"
import { modifyUser } from "./../actions"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import styles from "../components/RegisterForm/registerForm.module.css"
const netlifyIdentity = require("netlify-identity-widget")
const NETLIFY_FUNC = "farajaraftingchallenge.org/.netlify/functions"
const LOCAL_FUNC = "localhost:9000"
const API_URL = process.env.NODE_ENV === "development" ? `http://${LOCAL_FUNC}` : `https://${NETLIFY_FUNC}`;

const auth = new GoTrue({
  APIUrl: "https://farajaraftingchallenge.org/.netlify/identity",
  audience: "",
  setCookie: false
})

class RegistrationNewIndex extends React.Component {
  constructor(data) {
    super(data)
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.user === nextProps.user) {
      return false
    }
  }

  componentDidMount() {
    const user = auth.currentUser()
    if (user) this.navigateUser(user)
  }

  navigateUser = user => {
    let shouldNavToAdmin = false
    Object.keys(user.app_metadata).forEach(item => {
      if (item === "roles") {
        user.app_metadata.roles.find(role => {
          if (role === "admin") {
            shouldNavToAdmin = true
            return
          }
        })
      }
    })
    if (shouldNavToAdmin) {
      navigate("/admin/")
      return
    } else {
      fetch(`${API_URL}/queryForTeam?teamId=${user.id}`)
        .then(response => {
          if (response.ok) {
            response.json().then(data => {
              const teamData = data.data.filter(
                entry => entry[0] === user.id
              )[0]
              if (teamData !== undefined && teamData !== null) {
                navigate("/team/")
                return
              } else {
                navigate("/createTeam")
                return
              }
            })
          } else {
            response.json().then(error => {
              console.log(
                `Request failed - Status code ${response.status}\nError:`,
                error
              )
              navigate("/createTeam")
              return
            })
          }
        })
        .catch(err => {
          console.log(`Fetch error: ${err}`)
          navigate("/createTeam")
          return
        })
    }
  }

  onSignupClick = () => {
    netlifyIdentity.open("signup") // open the modal to the signup tab
  }

  nextCard = () => {
    this.slider.slickNext()
  }

  prevCard = () => {
    this.slider.slickPrev()
  }

  render() {
    const user = this.props.reduxUser
    const siteTitle = get(this, "props.data.site.siteMetadata.title")
    const allSections = get(this, "props.data.allContentfulBlogPost.edges")
    const hero = allSections.filter(
      a => a.node.tags && a.node.tags.includes("Hero")
    )[0]

    const settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <React.Fragment></React.Fragment>,
      prevArrow: <React.Fragment></React.Fragment>
    }

    return (
      <Layout>
        <Helmet title={`Register | ${siteTitle}`} />
        <PageHero hero={hero.node} user={user} />
        <Container>
          <Slider className="row" ref={c => (this.slider = c)} {...settings}>
            <div
              className={`${styles.package_card} col-10 co-sm-10 col-md-6 col-lg-6 align-self-center`}
            >
              <div
                className={`${styles.package_card_banner} raft ${styles.raft}`}
              >
                <span className={`${styles.package_card_icon}`}></span>
              </div>
            
            <div className={styles.card_content}>
            <h2>Join the raft</h2>
                <p>
                We invite corporates and individuals to register and be part of
                the change that will make a difference in the lives of people
                with cancer in Kenya. A team comprises 5 members above the age
                of 18.
              </p>
            </div>
              <button className={`${styles.btn} btn`} onClick={this.nextCard}>
                ☞ ☞ ☞
              </button>
            </div>

            <div
              className={`${styles.package_card} col-10 co-sm-10 col-md-6 col-lg-6 align-self-center ${styles.silver}`}
            >
              <div className={styles.package_card_banner}>
                <span className={`${styles.package_card_icon}`}></span>
              </div>
              <div className={styles.card_content}>
              <h2>Early Bird Registration Fees</h2>
              <p>
              Early bird registration fee is KES 120, 000<br/> up to 15th April 2024.
                After this date the registration fee will be KES 150, 000
              </p>
              </div>
              <button className={`${styles.btn} btn`} onClick={this.nextCard}>
                ☞ ☞ ☞
              </button>
            </div>

            <div
              className={`${styles.package_card} col-9 co-sm-9 col-md-6 col-lg-6 align-self-center ${styles.sign_up}`}
            >
              <div className={styles.package_card_banner}>
                <span className={`${styles.package_card_icon}`}></span>
              </div>
              <div className={styles.card_content}>
              <p>
               If you're a Team Leader, click on "Sign up" below
              </p>
              <p> Create and submit your team after picking the rafting day/time appropriate to you and your members</p>{" "}
              </div>
              <a
                className={`${styles.btn} btn ${styles.sign_up_btn}`}
                style={{ cursor: "pointer", marginBottom: 10 }}
                onClick={this.onSignupClick}
              >
               Sign up
              </a>
            </div>
          </Slider>

          <div className="row justify-content-center text-center register">
            <div className={`col-md-12`}>
              {hero.node.body && (
                <div
                  className="foot-note mb-4 mt-4"
                  dangerouslySetInnerHTML={{
                    __html: hero.node.body.childMarkdownRemark.html
                  }}
                />
              )}
            </div>
          </div>
        </Container>
      </Layout>
    )
  }
}

const mapStateToProps = state => ({
  reduxUser: state.user
})

const mapDispatchToProps = () => {
  return {
    modifyUser
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(RegistrationNewIndex)

export const pageQuery = graphql`
  query registerNewQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulBlogPost(
      filter: { tags: { eq: "Register" } }
      sort: { fields: [sort], order: ASC }
    ) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          heroImage {
            sizes(maxWidth: 1920, resizingBehavior: SCALE) {
              ...GatsbyContentfulSizes_withWebp
            }
          }
          photos {
            title
            sizes(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
              ...GatsbyContentfulSizes_withWebp
            }
          }
          body {
            childMarkdownRemark {
              html
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
